<template>
    <router-link :to="`/companies/${company.id}`" class="company-card">
        <img
            v-if="company.logo"
            :src="baseUrl + company.logo"
            class="company-card__logo"
            :alt="company.name"
        />
        <img
            v-else
            src="@/assets/images/empty-company-logo.svg"
            class="company-card__logo"
            :alt="company.name"
        />

        <div class="company-card__content">
            <h3 class="company-card__title">{{ company.name }}</h3>
            <p class="company-card__employees">
                Сотрудники:
                <span class="company-card__employees-num">{{
                        company.employees_count
                    }}</span>
            </p>
            <div class="company-card__term" v-if="company.contract_date_from">
                {{ dateText }}
                <VTooltip
                    text="Срок договора истекает"
                    class="company-card__term-tooltip"
                    v-if="days > 0 && days < 30 && startDate < 0"
                >
                    <template #icon>
                        <img src="@/assets/images/icons/tooltip-gray-fill.svg" alt="tooltip"/>
                    </template>
                </VTooltip>
            </div>
            <div class="company-card__term" v-else></div>
            <p class="company-card__status" v-if="company.has_contract">Активно</p>
            <p class="company-card__status company-card__status_blocked" v-else>
                Заблокировано
            </p>
          <router-link class="company-card__edit" :to="`/companies/${company.id}/edit-company`">
            <img src="@/assets/images/icons/pencil-black.svg" alt="edit">
          </router-link>
        </div>
    </router-link>
</template>

<script setup>
import {computed} from "vue";
import moment from "moment";

const props = defineProps({
    company: {
        type: Object,
        required: true,
    },
});

const baseUrl = process.env.VUE_APP_BASE;

const startDate = moment(props.company.contract_date_from).diff(moment(), "hours");
const days = moment(props.company.contract_date_to).add(1, 'd').diff(moment(), "days");
const date = moment(props.company.contract_date_to).format("DD.MM.YYYY");

const createLabel = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
}

const dateText = computed(() => {
    if (startDate > 0) return `Срок договора начнется с ${moment(props.company.contract_date_from).format("DD.MM.YYYY")}`
    if (days > 0) return `${date} (ост. ${days} ${createLabel(days, ['день', 'дня', 'дней'])})`;
    return "Срок договора истёк";
});
</script>

<style scoped lang="scss">
.company-card {
    padding: 16px;
    height: 111px;
    border-radius: 16px;
    display: flex;
    background-color: #fff;
    color: $dark;
    text-decoration: none;
    cursor: pointer;
    transition: .3s;
    box-shadow: 2px 2px 8px 0px #1E39590A;
    position: relative;

    &:hover {
        border-color: #fff;
        box-shadow: 2px 2px 24px 0px #1E39591F;
    }

    &:hover &__edit {
      opacity: 1;
      display: flex;
    }

    &__logo {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        object-fit: contain;
        border: 1px solid $light-gray-1;
    }

    &__content {
        margin-left: 12px;
    }

    &__title {
        max-width: 240px;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__employees {
        margin-top: 4px;
        color: $gray;
        font-size: 12px;
        font-weight: 500;
    }

    &__employees-num {
        margin-left: 4px;
        border-radius: 4px;
    }

    &__term {
        height: 15px;
        margin-top: 8px;
        display: flex;
        color: $dark-blue;
        font-size: 12px;
        font-weight: 400;
    }

    &__term-tooltip {
        margin-left: 8px;
    }

    &__status {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 500;
        color: $blue;

        &_blocked {
            color: $red;
        }
    }

    &__edit {
      position: absolute;
      top: 12px;
      right: 10px;
      width: 28px;
      height: 28px;
      border-radius: 6px;
      display: none;
      align-items: center;
      justify-content: center;
      border: 1px solid $light-gray-1;
      cursor: pointer;
      opacity: 0.7;

    }


}
</style>
